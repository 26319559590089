<template>
  <SectionContainer :title="$t('home.location.title')">
    <div class="flex-wrap flex flex-1 w-full gap-4 items-center justify-center">
      <div
        class="rounded-md overflow-hidden relative text-center aspect-square w-32 md:w-unset"
        v-for="item in cities"
        :key="item.title"
      >
        <img
          :src="`/homepage/${item.image}`"
          :class="!item.isAvailable && 'grayscale'"
          class="w-full h-full object-cover rounded-md"
        />
        <div
          class="absolute inset-0 flex-center-none text-lg text-center font-semibold"
        >
          <h3 class="text-white" v-if="item.isAvailable">
            {{ $t(item.title) }}
          </h3>
          <h3 class="text-white text-shadow" v-else>
            {{ $t('home.location.coming_soon') }}
          </h3>
        </div>
      </div>
    </div>
  </SectionContainer>
</template>

<script setup lang="ts">
const cities = [
  {
    title: 'home.location.location1',
    isAvailable: true,
    image: 'location-1.png',
  },
  {
    title: 'home.location.location2',
    isAvailable: false,
    image: 'location-2.png',
  },
  {
    title: 'home.location.location3',
    isAvailable: false,
    image: 'location-3.png',
  },
  {
    title: 'home.location.location4',
    isAvailable: false,
    image: 'location-4.png',
  },
];
</script>

<style scoped></style>
