<template>
  <div class="hero-section">
    <img class="hero-img" src="/logo/hero.png" />
    <div class="absolute inset-0 z-2">
      <section
        class="auto-container flex flex-col items-start h-full justify-center gap-y-4 z-2 max-w-9/10"
      >
        <h1 class="md:text-5xl text-3xl max-w-3/5">
          {{ $t('hero.hero_title') }}
        </h1>
        <p class="max-w-3/5">
          {{ $t('hero.hero_subtitle') }}
        </p>

        <NuxtLink
          :to="{
            path: $l('/'),
            hash: '#get-app',
          }"
        >
          <button class="btn-primary btn-hover uppercase py-4 px-12 mt-10">
            {{ $t('hero.explore_the_app') }}
          </button>
        </NuxtLink>
        <div class="flex-center-none gap-2 h-8">
          <a
            class="h-full"
            href="https://apps.apple.com/ae/app/taza-express/id1614409775"
            target="_blank"
          >
            <img src="/logo/app-store.png" class="h-full object-contain" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.creativeadvtech.taza_mobile_production"
            class="h-full"
            target="_blank"
          >
            <img src="/logo/google-play.png" class="h-full object-contain" />
          </a>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.hero-img {
  --at-apply: 'sm:h-80 h-50 my-auto translate-y-50% sm:translate-y-30% md:translate-y-0 md:h-full absolute end-0 md:translate-none end-0 md:max-w-full object-cover transform rtl:scale-x-[-1] z-0';
}

.hero-section {
  --at-apply: 'w-full h-md md:h-xl relative from-secondary to-bg-primary bg-gradient-linear bg-gradient-to-b';
}
</style>
