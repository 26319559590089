<template>
  <SectionContainer :title="$t('home.intro.title')">
    <div
      class="w-full flex flex-1 max-w-4xl mx-auto gap-4 flex-wrap items-center justify-center"
    >
      <div
        class="flyer"
        v-for="(item, i) in items"
        :class="`flyer-${i + 1}`"
        :key="item.title"
      >
        <div class="absolute top-0 inset-x-0">
          <h3 class="title">
            {{ $t(item.title) }}
          </h3>
          <p class="description">
            {{ $t(item.description) }}
          </p>
        </div>

        <div class="flex-1"></div>
        <img
          class="image"
          v-if="item.image"
          :src="`/homepage/${item.image}`"
          alt="flyer"
        />
      </div>
    </div>
  </SectionContainer>
</template>

<script setup lang="ts">
const items = [
  {
    title: 'home.intro.head1',
    description: 'home.intro.desc1',
    class: 'bg-deliver-bg',
    image: 'Layer_x0020_1.png',
  },
  {
    title: 'home.intro.head2',
    description: 'home.intro.desc2',
    class: 'bg-offer-bg',
    image: 'Layer_x0020_2.png',
  },
  {
    title: 'home.intro.head3',
    description: 'home.intro.desc3',
    class: 'bg-basket-bg',
    image: 'Layer_x0020_3.png',
  },
];
</script>

<style scoped lang="scss">
.flyer {
  --at-apply: 'rounded-lg overflow-hidden text-start relative bg-no-repeat bg-cover';
  --at-apply: 'flex flex-none flex-col justify-end  rounded-md';
  --at-apply: 'w-240px md:flex-1 aspect-ratio-1/2';
  .title {
    --at-apply: 'px-5 pt-6 pb-4 font-semibold z-20 md:text-xl';
  }
  .description {
    --at-apply: 'px-5  font-normal';
  }
  .image {
  }
  &.flyer-1 {
    background: linear-gradient(
      177deg,
      #ebf0ce 0%,
      rgba(246, 231, 245, 0) 100%
    );
  }
  &.flyer-2 {
    background: var(
      --gradient-primary,
      linear-gradient(177deg, #f6e7f5 0%, rgba(246, 231, 245, 0) 100%)
    );
  }
  &.flyer-3 {
    background: linear-gradient(
      177deg,
      #fff3ce 0%,
      rgba(246, 231, 245, 0) 100%
    );
  }
}
</style>
