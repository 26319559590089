<template>
  <SectionContainer :title="$t('home.joinUs.title')">
    <div class="flex flex-1 flex-wrap gap-4 items-center justify-center">
      <div class="card">
        <img
          src="/homepage/image-2.png"
          alt=""
          class="aspect-square h-full object-cover"
        />
        <div class="flex flex-col justify-between items-start p-4">
          <h3 class="text-xl md:text-2xl text-start font-medium">
            {{ $t('home.joinUs.head1') }}
          </h3>
          <p class="text-sm md:text-base text-start">
            {{ $t('home.joinUs.desc1') }}
          </p>
          <NuxtLink :to="$l('/contact')">
            <button class="btn">
              {{ $t('home.joinUs.btn1') }}
            </button>
          </NuxtLink>
        </div>
      </div>
      <div class="card">
        <img
          src="/homepage/image-3.png"
          alt=""
          class="aspect-square h-full object-cover"
        />
        <div class="flex flex-col justify-between items-start p-4">
          <h3 class="text-xl md:text-2xl text-start font-medium">
            {{ $t('home.joinUs.head2') }}
          </h3>
          <p class="text-sm md:text-base text-start">
            {{ $t('home.joinUs.desc2') }}
          </p>
          <a href="mailto: jobs@taza.iq" target="_blank">
            <button class="btn">
              {{ $t('home.joinUs.btn2') }}
            </button>
          </a>
        </div>
      </div>
    </div>
  </SectionContainer>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.card {
  --at-apply: 'flex-none md:flex-1 flex flex-row bg-bg-secondary md:h-48  h-36 rounded-lg overflow-hidden w-9/10 md:w-1/2';
}

.btn {
  --at-apply: 'btn-accent rounded-full text-sm w-32 md:text-base md:w-48';
}
</style>
