<template>
  <SectionContainer id="get-app" :title="$t('home.callToAction.title')">
    <div class="flex flex-wrap items-center justify-center gap-y-8">
      <div class="w-1/2 min-w-64 flex justify-center items-center flex-col">
        <h3 class="text-2xl font-normal text-start mb-5">
          {{ $t('home.callToAction.desc') }}
        </h3>

        <div class="flex-center-none gap-2 h-12 min-w-64">
          <a
            class="h-full"
            href="https://apps.apple.com/ae/app/taza-express/id1614409775"
            target="_blank"
          >
            <img src="/logo/app-store.png" class="h-full object-contain" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.creativeadvtech.taza_mobile_production"
            class="h-full"
            target="_blank"
          >
            <img src="/logo/google-play.png" class="h-full object-contain" />
          </a>
        </div>
      </div>
      <div class="aspect-square md:w-1/2 min-h-96 w-full flex-center-none">
        <img
          src="/homepage/app-demo.png"
          alt=""
          class="h-full h-full object-contain"
          v-if="locale === 'en'"
        />
        <img
          src="/homepage/app-demo-ar.png"
          alt=" w-full h-full object-contain"
          v-else
        />
      </div>
    </div>
  </SectionContainer>
</template>

<script setup lang="ts">
const { locale } = useI18n();
</script>

<style scoped></style>
